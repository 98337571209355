<!-- 审核弹窗 -->
<template>
  <div class="">
    <el-dialog
      :title="title"
      :visible.sync="visible"
      width="50%"
      @close="closeModal"
      :close-on-click-modal="false"
    >
      <!-- 人员信息 -->
      <a-descriptions
        style="
          word-break: break-word;
          word-wrap: break-word;
          white-space: pre-wrap;
        "
        layout="vertical"
        title="服务详情"
      >
        <a-descriptions-item label="订单编号">
          {{ info.orderSn	 || "--" }}
        </a-descriptions-item>
        <a-descriptions-item label="订单状态">
            <template v-if="info.status === 0">未支付</template>
            <template v-else-if="info.status === 1">已完成</template>
            <template v-else-if="info.status === 2">退款中</template>
            <template v-else-if="info.status === 3">已关闭</template>
        </a-descriptions-item>
        <a-descriptions-item label="下单时间">{{
          info.createTime || "--"
        }}</a-descriptions-item>
        <a-descriptions-item label="服务项目">{{
          info.productName || "--"
        }}</a-descriptions-item>
        <a-descriptions-item label="认证公司">{{
          info1.enterpriseName || "--"
        }}</a-descriptions-item>
        <a-descriptions-item label="服务费用">{{
          info.payAmount || "--"
        }}</a-descriptions-item>
        <a-descriptions-item label="服务说明">{{
          info1.description || "--"
        }}</a-descriptions-item>
      </a-descriptions>
      <!-- 事件信息 -->
      <a-descriptions
        style="
          word-break: break-word;
          word-wrap: break-word;
          white-space: pre-wrap;
        "
        layout="vertical"
        title="客户信息"
      >
        <a-descriptions-item label="客户姓名">{{
          info.memberName || "--"
        }}</a-descriptions-item>
        <a-descriptions-item label="联系电话">{{
          info.memberPhone || "--"
        }}</a-descriptions-item>
        <!-- <a-descriptions-item label="微信号">{{
          info.createTime || "--"
        }}</a-descriptions-item> -->
      </a-descriptions>
      <!-- 审核结果 -->
      <a-descriptions
        style="
          word-break: break-word;
          word-wrap: break-word;
          white-space: pre-wrap;
        "
        layout="vertical"
        title="公司信息"
      >
        <a-descriptions-item label="认证公司">{{
         info1.enterpriseName || "--"
        }}</a-descriptions-item>
        <a-descriptions-item label="法人信息">{{
          info1.enterpriseUserName || '--'
        }}</a-descriptions-item>
       <a-descriptions-item label="统一社会信用代码">{{
          info1.enterpriseNumber || '--'
        }}</a-descriptions-item>
      </a-descriptions>
      <!-- footer -->
      <span slot="footer" class="dialog-footer">
        <el-button
          @click="visible = false"
          type="primary"
          style="background-color: #556bff"
          >返 回</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
  
  <script>
import api from "../api.js";
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {  },
  data() {
    //这里存放数据
    return {
      api,
      visible: false,
      info: {},
      info1:{},
      title: "订单详情",
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {

  },
  //方法集合
  methods: {
    // 打开弹窗
    openModal(record = {}) {
      this.visible = true;
      this.getPointsById(record.id);
    },
    // 根据id查详情
    async getPointsById(id) {
      try {
        const res = await api.detail(id);
        this.info = res.data;
        this.info1 = this.info.scOrderItems[0]
      } catch (error) {
        console.error(error); // 处理错误
      }
    },
    closeModal() {
      this.visible = false;
    },

  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
  <style lang='less' scoped>
/deep/.el-dialog {
  border-radius: 20px !important;
}
/deep/.el-dialog__title {
  color: #2d3138;
}
/deep/.ant-descriptions-view {
  padding-left: 1%;
}
/deep/.ant-descriptions-title {
  font-size: 0.08rem;
  border-left: 4px solid #2a5cff;
  padding-left: 0.5%;
  line-height: 100%;
}

/deep/.ant-descriptions-item-label {
  color: #7d8da6 !important;
  padding-left: 4px;
}

/deep/.ant-descriptions-item-content {
  color: #2d3138;
}
// .titleBox {
//   display: flex;
//   align-items: center;
//   font-size: 0.08rem;
//   font-weight: 700;
//   color: #2d3138;
//   margin-bottom: 10px;
//   .titleLine {
//     height: 0.09rem;
//     width: 0.015rem;
//     background-color: #2a5cff;
//     margin-right: 5px;
//   }
// }
.orange {
  color: #e6a23c;
}
.green {
  color: #67c23a;
}
.red {
  color: red;
}
</style>